.inserted {
  background-color: #8ed6ff;
  background-color: var(--adalabBlueTrans);
}

.not-inserted {
  background-color: #ffe3b0;
}

.current {
  background-color: #ffc1c8;
}

.abandoned {
  background-color: var(--adalabRedTrans);
}

.active {
  cursor: pointer;
}
