:root {
  --adalabBlueDark: #099d8d;
  --adalabBlueMid: #0bb8a5;
  --adalabBlue: #14d9c4;
  --adalabBlueTrans: #14d9c42e;
  --adalabRedTrans: #ff000054;
}

body {
  font-family: 'Roboto', sans-serif;
}

.NavBar {
  background: linear-gradient(
    90deg, 
    var(--adalabBlueMid) 0%,
    var(--adalabBlue) 100%);
  color: white;
}

.tabs {
  font-weight: bold;
}

.table th {
  text-transform: uppercase;
  font-weight: bold;
}

.table tbody tr {
  cursor: pointer;
}

.grow{
  flex-grow: 1;
}

.menuButton {
  margin-left: -12;
  margin-right: 20;
}

.select {
  min-width: 150px;
  margin-bottom: 16px;
}

.input {
  min-width: 180px;
  margin: 16px;
}
.input--label {
  margin-left: 16px;
}

.select--label {
  margin-left: 16px;
}

.search-form {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container {
  margin: 16px;
  padding: 16px;
}

[class*="MuiCardActions-root"] {
  padding: 16px 16px 0 !important;
}