:root {
  --adalabBlueDark: #099d8d;
  --adalabBlueMid: #0bb8a5;
  --adalabBlue: #14d9c4;
  --adalabBlueTrans: #14d9c42e;
  --adalabRedTrans: #ff000054; }

body {
  font-family: 'Roboto', sans-serif; }

.NavBar {
  background: -webkit-gradient(linear, left top, right top, from(#0bb8a5), to(#14d9c4));
  background: linear-gradient(90deg, #0bb8a5 0%, #14d9c4 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--adalabBlueMid)), to(var(--adalabBlue)));
  background: linear-gradient(90deg, var(--adalabBlueMid) 0%, var(--adalabBlue) 100%);
  color: white; }

.tabs {
  font-weight: bold; }

.table th {
  text-transform: uppercase;
  font-weight: bold; }

.table tbody tr {
  cursor: pointer; }

.grow {
  flex-grow: 1; }

.menuButton {
  margin-left: -12;
  margin-right: 20; }

.select {
  min-width: 150px;
  margin-bottom: 16px; }

.input {
  min-width: 180px;
  margin: 16px; }

.input--label {
  margin-left: 16px; }

.select--label {
  margin-left: 16px; }

.search-form {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%; }

.container {
  margin: 16px;
  padding: 16px; }

[class*="MuiCardActions-root"] {
  padding: 16px 16px 0 !important; }

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.layout {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.stripedRows tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.highlighted {
  background-color: #f3f3f3;
}

.highlighted td {
  font-weight: bold;
}

.large {
  width: 40%;
}

.medium {
  width: 20%;
}

.inserted {
  background-color: #8ed6ff;
  background-color: var(--adalabBlueTrans);
}

.not-inserted {
  background-color: #ffe3b0;
}

.current {
  background-color: #ffc1c8;
}

.abandoned {
  background-color: var(--adalabRedTrans);
}

.active {
  cursor: pointer;
}

