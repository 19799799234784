.stripedRows tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.highlighted {
  background-color: #f3f3f3;
}

.highlighted td {
  font-weight: bold;
}

.large {
  width: 40%;
}

.medium {
  width: 20%;
}
