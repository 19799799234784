.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.layout {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}
